import Vue from 'vue';
import {getInstance} from '@snapshot-labs/lock/plugins/vue';
import {Web3Provider} from '@ethersproject/providers';
import store from '@/store';
import config from '@/helpers/config';
import getProvider from '@/helpers/provider';
import {formatUnits} from '@ethersproject/units';

// let wsProvider;
let auth;

// if (wsProvider) {
//   wsProvider.on('block', blockNumber => {
//     store.commit('GET_BLOCK_SUCCESS', blockNumber);
//   });
// }
const state = {
    account: null,
    name: null,
    network: config.networks[config.defaultNetwork]
};

const mutations = {
    LOGOUT(_state) {
        Vue.set(_state, 'account', null);
        Vue.set(_state, 'name', null);
        console.debug('LOGOUT');
    },
    LOAD_PROVIDER_REQUEST() {
        console.debug('LOAD_PROVIDER_REQUEST');
    },
    LOAD_PROVIDER_SUCCESS(_state, payload) {
        Vue.set(_state, 'account', payload.account);
        Vue.set(_state, 'name', payload.name);
        console.debug('LOAD_PROVIDER_SUCCESS');
    },
    LOAD_PROVIDER_FAILURE(_state, payload) {
        Vue.set(_state, 'account', null);
        console.debug('LOAD_PROVIDER_FAILURE', payload);
    },
    HANDLE_CHAIN_CHANGED(_state, chainId) {
        if (!config.networks[chainId]) {
            config.networks[chainId] = {
                ...config.networks['1'],
                chainId,
                name: 'Unknown',
                network: 'unknown'
            };
        }
        Vue.set(_state, 'network', config.networks[chainId]);
        console.debug('HANDLE_CHAIN_CHANGED', chainId);
    },
    HANDLE_ACCOUNTS_CHANGED(_state, payload) {
        Vue.set(_state, 'account', payload);
        console.debug('HANDLE_ACCOUNTS_CHANGED', payload);
    }
};

const actions = {
    login: async ({dispatch}, connector = 'injected') => {
        auth = getInstance();
        await auth.login(connector);
        if (auth.provider) {
            auth.web3 = new Web3Provider(auth.provider);
            await dispatch('loadProvider');
            if (
                parseInt(auth.web3._network.chainId) != 106 &&
                parseInt(auth.web3._network.chainId) != 111
            ) {
                await dispatch('notify', ['red', `network errors`]);
            }
        }
    },
    logout: async ({commit}) => {
        Vue.prototype.$auth.logout();
        commit('LOGOUT');
    },
    loadProvider: async ({commit, dispatch}) => {
        commit('LOAD_PROVIDER_REQUEST');
        try {
            if (auth.provider.removeAllListeners) auth.provider.removeAllListeners();
            if (auth.provider.on) {
                auth.provider.on('chainChanged', async chainId => {
                    commit('HANDLE_CHAIN_CHANGED', parseInt(formatUnits(chainId, 0)));
                    if (parseInt(chainId) != 106 && parseInt(chainId) != 111) {
                        await dispatch('notify', ['red', `network errors`]);
                    }
                });
                auth.provider.on('accountsChanged', async accounts => {
                    if (accounts.length !== 0) {
                        commit('HANDLE_ACCOUNTS_CHANGED', accounts[0]);
                        await dispatch('loadProvider');
                    }
                });
                auth.provider.on('disconnect', async () => {
                    commit('HANDLE_CLOSE');
                });
            }
            const [network, accounts] = await Promise.all([
                auth.web3.getNetwork(),
                auth.web3.listAccounts()
            ]);
            commit('HANDLE_CHAIN_CHANGED', network.chainId);
            const account = accounts.length > 0 ? accounts[0] : null;
            const provider = await getProvider(106);

            //const ENSName = await provider.lookupAddress(account);
            const ENSName = null;
            commit('LOAD_PROVIDER_SUCCESS', {
                account,
                ENSName
            });
        } catch (e) {
            commit('LOAD_PROVIDER_FAILURE', e);
            return Promise.reject();
        }
    }
};

export default {
    state,
    mutations,
    actions
};
