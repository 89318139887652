export default {
    newProposal: '新提案',
    proposals: '提案',
    suggestMsg: '这里还没有任何建议！',
    all: '全部',
    pending: '待处理',
    active: '进行中',
    canceled: '取消',
    defeated: '失败',
    succeeded: '成功',
    queued: '排队中',
    expired: '已过期',
    executed: '已执行',
    delegatee: '委托人地址',
    delegate: '代理',
    setDelegatee: '设置委托人',
    connectWallet: '连接钱包',
    logOut: '登出',
    account: '账户',
    about: '关于',
    address: '地址',
    createTitle: '标题',
    targets: '目标',
    addTarget: '添加目标',
    values: '值',
    addValue: '添加值',
    signatures: '签名',
    addSignature: '添加签名',
    calldatas: '调用数据',
    addCalldata: '添加调用数据',
    action: '行动',
    publish: '发布',
    voteTitle: '投你的票',
    vote: '投票',
    votes: '投票',
    seeMore: '查看更多',
    information: '信息',
    author: '作者',
    id: '编号',
    startDate: '开始日期',
    endDate: '结束日期',
    results: '结果',
    currentResults: '当前结果',
    queue: '队列',
    execute: '执行',
    eta: 'Eta',
    downloadReport: '下载报告',
    confirm: '确认',
    confirmVote: '确认投票',
    confirmVoteTip: '您确定要投票吗',
    confirmVoteTip2: '此操作无法撤消。',
    confirmProposal: '确认提案',
    cancel: '取消',
    option: '选项',
    votingPower: '您的投票权',
    noDelegateeTip: '您尚未设置委托人',
    votesZeroTip: '你的选票是零',
    delegateeNoSelfTip: '委托人不是你自己',
    addAction: '添加行动',
    function: '方法',
    params: '参数',
    body: '你有什么建议？',
    choose: '选择...',
    transaction: '交易明细',
    proposalParams: '预计执行内容',
    addPool: '添加新池,设置奖励比例为{ratio} lpToken地址{lpToken}',
    setRewardRatio: '交易池 ID:{poolId}，设置奖励比例为{ratio}',
    setConnectorImpl: '设置连接器逻辑地址 交易池 ID：{poolId} 逻辑地址 {address}',
    mintSyx: '向奖励池 {farm} 发行数量为 {amount} 的 SYX 代币',
    proposalParamsInput: '提案输入参数',
    proposalParamsSend: '提案提交参数'
};
